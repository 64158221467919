import React from 'react'

import {
  ChakraProvider,
  extendTheme,
  theme as chakraTheme,
} from '@chakra-ui/react'

import "@fontsource/space-mono";
import '@fontsource-variable/inter';

const theme = extendTheme({
  fonts: {
    heading: `'Inter Variable', sans-serif`,
    body: `'Inter Variable', sans-serif`,
  },
  styles: {
    global: {
      body:{
        backgroundColor: '#F1F0F8',
        color: '#382813'
      },
      a: {
        textDecoration: 'underline',
        _hover:{
          fontStyle: 'italic'
        }
      }
    }
  },
  components: {
    Input: {
      baseStyle: {
        field: {
          borderWidth: "1px",
          borderColor: "#382813",
          borderRadius: "4px",
        },
      }
    },
    Select: {
      baseStyle: {
        field: {
          borderWidth: "1px",
          borderColor: "#382813",
          borderRadius: "4px",
        },
      }
    },
    Text:{
      variants:{
        mono:{
          fontFamily: `'Space Mono', sans-serif`
        }
      }
    }
  }
})

export default function RootLayout({ children }) {
  return (
    <>
      <ChakraProvider theme={theme}>
          {children}
      </ChakraProvider>
    </>
  );
}